<template>
	<div>
		<div class="rebate css">
			<div class="top-cover">
				<img src="../../assets/home/cover.png" alt>
			</div>

			<div class="nav">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>返利搜索</el-breadcrumb-item>
				</el-breadcrumb>
			</div>

			<div class="cont">
				<!-- <a-input v-model:value="input" size='large' placeholder="请输入要搜索的链接" allow-clear /> -->
				<el-input placeholder="请输入要搜索的链接" v-model="input" clearable></el-input>

				<div class="search-btn" @click="handleRebate"></div>

				<div class="search-wrap">
					<div class="title rse">搜索结果</div>
					<div v-if="result" class="result rss">
						<img src="../../assets/common/cover.png" class="img">
						<div class="panel css">
							<img src="~assets/home/taobao.png" width="30" height="30">
							<div class="goods-name">包包链条配件背包单肩包斜跨包金色链子</div>
							<div class="goods-price rbc">
								<div class="price rsc">
									<div id="money">￥199</div>
									<span>预计返现￥2.00</span>
								</div>
								<div>累计销量：1000件</div>
							</div>
							<div class="btn-box rbc">
								<el-button type="danger">立即领券</el-button>
								<el-button>复制优惠</el-button>
							</div>
						</div>
					</div>
					<el-empty v-else></el-empty>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			input: '',
			result: true,
		}),
		methods: {
			handleRebate() {

			}
		}
	}
</script>

<style lang="scss" scoped>
	.rebate {
		width: 1200px;
		margin: 0 auto;
		background: #fff;
		position: relative;
		height: 880px;

		.top-cover {
			position: absolute;
			height: 451px;
			text-align: center;
			z-index: 0;

			img {
				width: 100%;
				height: 100%;
				display: block;
			}
		}

		.nav {
			padding: 20px;
			position: absolute;
			z-index: 1;
		}

		.cont {
			width: 1200px;
			// min-height: 50vh;
			text-align: center;
			padding-top: 15%;
			position: absolute;

			.el-input {
				width: 800px;
				height: 50px;

				/deep/ .el-input__inner {
					width: 800px;
					height: 50px;
					font-size: 16px;
					color: #000;
				}
			}

			.search-btn {
				width: 205px;
				height: 48px;
				background: url(../../assets/home/search.png);
				background-size: 100%;
				margin: 45px auto 0;
				cursor: pointer;
			}
		}
	}

	.search-wrap {
		width: 1000px;
		box-sizing: border-box;
		padding: 25px 20px 30px;
		margin-top: 5%;
		margin: 5% auto 0;
		background: #fff;
		box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);

		.title {
			font-family: PingFang SC;
			font-weight: 500;
			height: 20px;
			font-size: 16px;
			margin-left: 15px;

			&::before {
				content: '';
				display: block;
				width: 3px;
				height: 16px;
				transform: translateX(-5px);
				background: #3F7CF8;
			}
		}

		.result {
			margin: 33px 20px;

			.img {
				width: 154px;
				height: 154px;
				margin-right: 72px;
			}

			.panel {
				flex: 1;
				position: relative;

				img {
					position: absolute;
					left: -40px;
				}

				.goods-name {
					height: 30px;
					line-height: 30px;
					font-size: 1.2rem;
					color: #666;
				}

				.goods-price {
					width: 90%;
					height: 58px;
					line-height: 58px;
					padding-right: 50px;
					box-sizing: border-box;
					font-size: 1rem;
					color: #999999;
					border-bottom: 1px solid #f5f5f5;

					.price {
						width: 400px;
						color: #FF0000;

						#money {
							font-size: 1.2rem;
							font-weight: bold;
							text-align: left;
							width: 120px;
						}
					}
				}
				
				.btn-box {
					margin-top: 42px;
				}
			}
		}
	}

	.el-breadcrumb {
		/deep/ .is-link {
			color: #fff
		}

		/deep/ .el-breadcrumb__inner {
			color: #ececec
		}
	}
	
	.el-button--danger {
		width: 147px;
		height: 50px;
		margin-right: 42px;
		background: #EE0000;
		font-size: 1.1rem;
	}
	
	.el-button--default {
		width: 147px;
		height: 50px;
		font-size: 1.1rem;
	}
</style>
